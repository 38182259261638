var page_home_loaded = 1;
global.page_home_loaded = page_home_loaded;

global.jQuery = require('jquery');
var slickInit = require('slick-carousel');

page_home = {
	load: function(){
		this.homeTenutaSlider();
		this.homeWineSlider();
		this.homeTenutaAnim();
		this.homeWinesAnim();
		this.homeAnforeAnim();
		this.homeCompAnim();
	},
	homeTenutaAnim: function(){
	    var controller = new smInit.Controller(),
	      tl = new tmInit(),
	      ten = $('.homeTenuta'),
	      tenText = $('.homeTen .homeTenuta__textBox > *'),
	      tenGal = $('.homeTen .homeTenuta__gal'),

	      tween = tl.to(ten, .4, {top: 0}, "-=1")
	      			.staggerTo(tenText , .25, {top:0, opacity:1}, .2, "-=.3")
	      			.to(tenGal, .6, {left:0, opacity:1}, "-=.8"),
	      
	      scene = new smInit.Scene({reverse: true, triggerElement: $('#homeTenuta__trig')[0]}).setTween(tween).addTo(controller);
	},
	homeWinesAnim: function(){
	    var controller = new smInit.Controller(),
	      tl = new tmInit(),
	      wine = $('.homeWines'),
	      wineGal = $('.homeWines__gal'),

	      tween = tl.to(wine, .4, {top: 0}, "-=1")
	      			.to(wineGal, .6, {top:0, opacity: 1}),
	      
	      scene = new smInit.Scene({reverse: true, triggerElement: $('#homeWines__trig')[0]}).setTween(tween).addTo(controller);
	},
	homeAnforeAnim: function(){
	    var controller = new smInit.Controller(),
	      tl = new tmInit(),
	      anfImg = $('.homeAnfore__imageWrap'),
	      tween = tl.to(anfImg, .6, {top:0}, "+=1"),
	      
	      scene = new smInit.Scene({reverse: true, triggerElement: $('#homeAnfore__trig')[0]}).setTween(tween).addTo(controller);
	},
	homeCompAnim: function(){
	    var controller = new smInit.Controller(),
	      tl = new tmInit(),
	      ten = $('.homeCantina'),
	      tenText = $('.homeCantina .homeTenuta__textBox > *'),
	      tenGal = $('.homeCantina__galBox'),

	      tween = tl.to(ten, .4, {top: 0}, "-=1")
	      			.staggerTo(tenText , .25, {top:0, opacity:1}, .2, "-=.3")
	      			.to(tenGal, .6, {top:0, opacity:1}, "-=.8"),
	      
	      scene = new smInit.Scene({reverse: true, triggerElement: $('#homeCantina__trig')[0]}).setTween(tween).addTo(controller);
	},
	homeTenutaSlider: function(){
		jQuery('.homeTenuta__gal').slick({
			slidesToShow: 1,
		  	slidesToScroll: 1,
		  	speed: 450,
		  	arrows: true,
		  	dots: true,
		  	infinite: true,
		  	touch: false,
		  	touchMove: false,
		});
		jQuery('.homeTenuta__gal .slick-dots li button').each(function(){
			var currVal = $(this).text();
			$(this).empty().text('0'+currVal);
		});

		jQuery('.homeTenuta__gal').on('beforeChange', function(){
			$('.homeTenuta__galEl').addClass('changing');
		});
		jQuery('.homeTenuta__gal').on('afterChange', function(){
			$('.homeTenuta__galEl').removeClass('changing');
		});
	},
	homeWineSlider: function(){
		jQuery('.homeWines__gal').slick({
			slidesToShow: 1,
		  	slidesToScroll: 1,
		  	speed: 700,
		  	arrows: true,
		  	dots: true,
		  	infinite: true,
		  	touch: false,
		  	touchMove: false,
		  	autoplay: true,
	        autoplaySpeed: 4500,
	        lazyLoad: 'ondemand',
		  	responsive: [{
		  		breakpoint: 767,
	  			settings: {
	  				touch: true,
		  			touchMove: true,
		      	}
		  	}]
		});
		jQuery('.homeWines__gal .slick-dots li button').each(function(){
			var currVal = $(this).text();
			if (currVal < 10) {
				$(this).empty().text('0'+currVal);
			} else {
				$(this).empty().text(currVal);
			}
		})
		jQuery('.homeTenuta__gal').on('beforeChange', function(){
			siteGlobal.lazyLoad();
		});
	}
}

$(window).on('load', function(){
	page_home.load();
})